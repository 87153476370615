<template>
  <div class="project-details-page">
    <page-header
      :cover="require('../assets/images/project-details-cover.png')"
    />
    <div class="container">
      <div class="row my-5 py-5">
        <div class="col-12 col-lg-6">
          <h2 class="sec-title mb-5">{{ $t("challenge") }}</h2>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
        </div>
        <div class="col-12 col-lg-6 text-center">
          <img src="../assets/images/iphone.png" class="img-fit" alt="Iphone" />
        </div>
      </div>
    </div>
    <div
      class="sec-cover"
      :style="`background-image: url('${require('../assets/images/iphones.png')}')`"
    ></div>
    <div class="container">
      <div class="row my-5 py-5">
        <div class="col-12 col-lg-6 text-center">
          <img
            src="../assets/images/iphonex.png"
            class="img-fit"
            alt="Iphone"
          />
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2 class="sec-title mb-5">{{ $t("solution") }}</h2>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
          <p class="sec-desc mb-5">
            {{ $t("testTxt") }} {{ $t("testTxt") }} {{ $t("testTxt") }}
            {{ $t("testTxt") }} {{ $t("testTxt") }}
          </p>
        </div>
      </div>
      <div class="used-techs text-center">
        <h2 class="sec-title">{{ $t("usedTechnologies") }}</h2>
        <div class="d-flex justify-content-center align-items-center">
          <img src="../assets/images/ios.png" alt="IOS" />
          <img src="../assets/images/android.png" alt="android" />
          <img src="../assets/images/php.png" alt="php" />
          <img src="../assets/images/react.png" alt="react" />
          <img src="../assets/images/js.png" alt="js" />
          <img src="../assets/images/symfony.png" alt="symfony" />
        </div>
      </div>
      <div class="related-projects my-5 py-5">
        <h2>{{ $t("relatedProjects") }}</h2>
        <div class="d-flex justify-content-center flex-wrap">
          <project-card
            v-for="(proj, i) in projects"
            :key="i"
            :project="proj"
            @openProject="toggelModal"
          />
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <modal
        :project="modalData"
        :link="link"
        :btnName="btnName"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import ProjectCard from "../components/Layout/projectCard.vue";
import Modal from "../components/Layout/Modal.vue";
import PageHeader from "../components/misc/PageHeader.vue";
export default {
  components: { PageHeader, ProjectCard, Modal },
  data() {
    return {
      modalData: null,
      link: null,
      btnName: null,
      activeModal: false,
      projects: [
        {
          cover: require("../assets/images/proj-1.png"),
          title: "Sample Project",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 1,
        },
        {
          cover: require("../assets/images/proj-2.png"),
          title: "Sample Project 2",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 2,
        },
        {
          cover: require("../assets/images/proj-3.png"),
          title: "Sample Project 3",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 3,
        },
      ],
    };
  },
  methods: {
    toggelModal(proj) {
      this.activeModal = !this.activeModal;
      if (proj) {
        this.modalData = proj;
        this.btnName = "See Use Cases";
        this.link = {
          name: "ProjectDetails",
          params: { id: proj.id },
        };
      }
    },
  },
};
</script>
